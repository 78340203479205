#dfm-home-top {
   padding-left: 95px;
   margin-top:40px;
}

#dfm-home-top h2 {
   margin-top: 0px;
}

#dfm-home-middle {
   padding: 25px 50px 0px 50px;
   background: rgba(147,191,9,1);
   background: linear-gradient(rgba(181,230,29,1), rgba(147,191,9,1));
   /* box-shadow: inset 4px 7px 40px 3px rgba(109,109,109,0.35); */
}

#dfm-home-middle > div {
   margin-left: auto;
   margin-right: auto;
   max-width: 1200px;
}

#dfm-home-inner-content h1 {
   margin-top: 30px;
}

#dfm-home-middle h1 {
   color: #000;
   font-family: 'Indie Flower', cursive;
   font-size: 40px;
   text-align: center;
   margin-bottom: 0px;
}

#dfm-home-entrybuttons button {
   margin-right: 15px;
   font-size: 18px;
   margin-bottom: 10px;
   margin-top: 20px;
   background-color: #333;
}

#top-buttons {
   margin-bottom: 40px;
}

#dfm-home-entrybuttons {
   min-width: 500px;
   text-align: center;
}

#dfm-home-middle h2 {
   margin-bottom: 40px;
   font-weight: normal;
}

#dfm-home-banner {
   background-image: url('/images/DFMHomePagePhoto.jpg');
   background-repeat: no-repeat;
   background-size: cover;
   height: 400px;
   text-align: center;
   padding-right: 30px;
   padding-top: 20px;
   color: #fff;
}

#dfm-home-video {
   margin-right: 25px;
   margin-left: 40px;
   flex-grow: 0;
}

#dfm-home-inner {
   flex-grow: 2;
}

#dfm-home-inner-content {
   max-width: 560px;
   padding-right: 20px;
   padding-left: 30px;
   padding-bottom:10px;
}

#dfm-home-middle .very-large-button {
   margin-right:10px;
   margin-bottom: 10px;
   padding: 15px 30px 15px 15px;
   vertical-align: middle;
}

@media only screen and (min-width : 1130px) and (max-width : 1205px) {
   #dfm-home-middle .very-large-button {
      font-size: 20px;
   }
}
@media only screen and (max-width : 1129px) {
   #dfm-home-middle .very-large-button {
      font-size: 15px;
   }
}

#dfm-home-video {
   width: 500px;
}

@media only screen and (max-width : 900px) {
   #dfm-home-middle > div {
      flex-direction: column-reverse!important;
   }
   #dfm-home-video {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 40px;
   }
   #dfm-home-video iframe {
      width: 80%;
   }
   #dfm-home-middle {
      padding-left: 25px!important;
      padding-right: 25px;
   }
   #dfm-home-entrybuttons {
      padding-top: 0px;
      padding-bottom: 20px;
      min-width: auto!important;
   }
   #dfm-home-banner {
      background-position: center;
   }
}

.dfm-content-box {
   max-width: 100%;
}

#top-bar .inner {
   max-width: 100%;
}

#top-bar.top-bar-light {
    border-bottom: 0px!important;
}

#dfm-home-inner input[type=text], #dfm-home-inner input[type=password] {
   background-color: rgba(255,255,255,0.6);
   padding: 7px;
   font-size: 18px;
   border: 1px solid #fff;
   font-family: Lato, sans-serif;
   margin-right: 15px;
   margin-bottom: 30px;
}

@-webkit-keyframes autofill {
    to {
        background-color: rgba(255,255,255,0.6);
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

#page-home-questions-inner {
    margin-left: auto;
    margin-bottom: 50px;
    padding: 20px 50px 0px 50px;
    margin-right: auto;
    max-width: 1200px;
}

#page-home-questions-container {
   display: flex;
}

#page-home-questions-container > div {
   flex-grow: 1;
   width: 50%;
   background-color: #fafafa;
   border-radius: 10px;
   min-height: 150px;
   padding: 25px;
   margin: 10px;
   font-size: 16px;
}

@media only screen and (max-width : 1050px) {
   #page-home-questions-container > div {
      width: 100%!important;
      margin-right: 0px;
      margin-left: 0px;
   }
   #page-home-questions-container {
      flex-direction: column!important;
      padding-right: 20px;
   }
}

#example-keyskillquestion, #example-examskillquestion {
   background-color: #fff;
   padding: 20px;
   border: 1px solid #ddd;
   border-radius: 10px;
}

#page-home-questions-container div > p {
   margin-top: 0px;
}

#supporters-list {
   list-style-type: none;
   padding: 0px 0px 30px 0px;
}
#supporters-list li {
   display: inline-block;
   background-color: #fff;
   padding: 20px;
   border-radius: 10px;
}

#dfm-home-middle {
   padding-top: 30px!important;
   padding-bottom: 40px!important;
}

#page-home-1 {
   padding-top: 120px;
   padding-bottom: 170px;
}
#page-home-1 > div {
   max-width: 1000px;
   margin-left: auto;
   margin-right: auto;
}

#page-home2 {
   color: white;
   background-color: rgba(147,191,9,1); 
   padding: 40px 0px 0px 0px;
   max-height: 400px;
   overflow-y: clip;
   border-bottom: 1px solid #eee;
}
#page-home3 {
   padding: 90px 0px 0px 0px;
   max-height: 480px;
   overflow-y: clip;

}
#page-home4 {
   background-color: #f7f7f7;
   padding: 130px 0px 60px 0px;
}

#page-home2 > div, #page-home3 > div, #page-home4 > div {
   display:flex;
   max-width:1200px;
   margin-left:auto;
   margin-right:auto
}

.col-2-1 img {
   box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%), 0 0px 10px 15px rgb(0 0 0 / 19%);
   width:800px;
   border-radius: 10px 10px 0px 0px;
   margin-bottom: -3px;
}

.col-2-1 {
   padding-right: 40px;
}

.col-3-2 {
   padding-left: 50px;
}

.col-4-2 {
   max-width: 300px;
   margin-right: 50px;
}

.col-3-1 {
   padding-right: 30px;
}

.col-2-2 ol, .col-3-1 ol {
   font-size: 15px;
   margin-bottom: 40px;
}

.col-2-2 ol li, .col-3-1 ol li, .col-4-2 p {
   margin-bottom: 10px;
   font-weight: 100;
   font-size: 15px;
}

.col-4-2 p {
   margin-bottom: 30px!important;
}

.home-button {
   margin-right: 25px;
   margin-bottom: 60px;
   background-color: #333;
   font-size: 15px;
   font-weight: 700!important;
   padding: 15px;
   color: white;
   font-weight: normal;
   border: 0px;
   border-radius: 4px;
   cursor: pointer;
   font-family: Lato;
   display: inline-block;
}

.col-3-2 img {
   box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%), 0 0px 10px 15px rgb(0 0 0 / 19%);
   width:700px;
   border-radius: 10px 10px 0px 0px;
   margin-top: 115px;
   margin-bottom: -3px;
}

.quote-box {
   margin-bottom: 45px;
}

.quote-box quote {
   font-size: 26px;
   color: #444;
   display: block;
   margin-bottom: 20px;
   padding: 20px;
   border-radius: 15px;
   font-family: 'Indie Flower', cursive;
}

.quote-box .caption {
   font-size: 20px;
   font-weight: 600;
   color: purple;
   font-family: 'Indie Flower', cursive;
}

/* Adaptive logic */

@media only screen and (max-width : 650px) {
   #dfm-home-middle h1 {
      font-size: 30px;
   }
}

@media only screen and (min-width: 900px) and (max-width : 1250px) {
   .col-2-1 img {
      width: 90%;
      margin-left: 20px;
   }
   .col-2-1 {
      width: 60%;
      align-self: flex-end;
       height: 100%;
       overflow-y: clip;
   }
   .col-2-2 {
      padding-right: 20px;
      width: 40%;
   }
   #page-home2 {
      max-height: none;
      overflow-y: visible;
   }
   #page-home2 a.home-button {
      margin-bottom: 20px;
   }

}

@media only screen and (max-width: 899px) {
   #page-home2 > div {
      flex-direction: column;
   }
   .col-2-1 img {
      width: 100%;
   }
   .col-2-1 {
      padding-right:0px!important;
   }

   .col-2-2 {
      padding: 30px 30px 0px 30px;
   }
   #page-home2 {
      max-height: none;
      overflow-y: visible;
   }
}

@media only screen and (min-width: 900px) and (max-width : 1250px) {
   .col-3-2 img {
      width: 90%;
      margin-left: 20px;
   }
   .col-3-2 {
      width: 60%;
      align-self: flex-end;
      height: 100%;
      overflow-y: clip;
   }
   .col-3-1 {
      padding-left: 20px;
      width: 40%;
   }
   #page-home3 {
      max-height: none;
      overflow-y: visible;
   }
   #page-home3 a.home-button {
      margin-bottom: 20px;
   }
}

@media only screen and (max-width: 899px) {
   #page-home3 > div {
      flex-direction: column-reverse;
   }
   .col-3-2 img {
      width: 100%;
      margin-top: 0px;
   }
   .col-3-2 {
      padding-left:0px!important;
   }

   .col-3-1 {
      padding: 30px 30px 0px 30px;
   }
   #page-home3 {
      max-height: none;
      overflow-y: visible;
      padding-top: 0px;
   }
}

@media only screen and (min-width: 730px) and (max-width : 1250px) {
   .col-4-1 {
      padding-right: 30px;
   }
   .col-4-2 {
      padding-left: 30px;
   }
   #page-home4 a.home-button {
      margin-bottom: 15px;
   }
}

@media only screen and (max-width: 729px) {
   #page-home4 {
      padding-top: 40px;
   }
   #page-home4 > div {
      flex-direction: column-reverse;
   }
   .quote-box {
      margin-left: 30px;
      margin-right: 30px;
   }
   .quote-box quote {
      font-size: 20px;
      font-weight: 700;
   }
   .col-4-2 {
      max-width: none;
      margin-left: 30px;
      marign-left: 30px;
   }
   #page-home4 a.home-button {
      margin-bottom: 15px;
   }
}

@media only screen and (max-width: 900px) {
   #dfm-home-entrybuttons {
      display: block;
   }
   #dfm-home-entrybuttonslow {
      display: none;
   }
}

@media only screen and (min-width: 901px) {
   #dfm-home-entrybuttons {
      display: none;
   }
   #dfm-home-entrybuttonslow {
      display: block;
   }
}

#page {
   border-top: 1px solid rgba(151,220,29,1);
}

/* Style selectors for refreshed bottom section */
.homepage__section {
   display: grid;
   gap: 32px;
   grid-template-columns: repeat(1, minmax(0, 1fr));
   margin: 0 auto;
   padding: 48px 30px;
   width: 100%;
}

.homepage__section__title {
   font-size: 20px;
   margin: 0px;
   margin-bottom: 2px;
}

.homepage__section__subtitle {
   color: #374151;
   font-size: 14px;
   margin: 0px;
   margin-bottom: 20px;
}

.homepage__content {
   align-items: start;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.homepage__content__button {
   background-color: #333333;
   border-radius: 4px;
   color: white;
   cursor: pointer;
   font-family: Lato;
   font-size: 14px;
   font-weight: 700;
   padding: 15px;
}

.homepage__socials {
   display: flex;
   gap: 40px;
   margin: 0 auto;
}

.homepage__socials img {
   cursor: pointer;
   width: 40px;
}

/* sm breakpoint */
@media (min-width: 640px) {
   .homepage__section {
      max-width: 640px;
   }
   .homepage__socials {
      margin: 0;
   }
}

/* md breakpoint */
@media (min-width: 768px) {
   .homepage__section {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      max-width: 768px;
   }
}

/* lg breakpoint */
@media (min-width: 1024px) {
   .homepage__section {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      max-width: 1024px;
      padding: 48px;
   }
}

/* xl breakpoint */
@media (min-width: 1280px) {
   .homepage__section {
      max-width: 1280px;
   }
}